import {
  SOFIA_PRO_FONT_FACE,
  createDefaultFeeRows,
  createDefaultIncentivesCopy,
  createDefaultOffers,
  createDefaultRewards,
  createTypographyStyle,
} from '~/constants/templates/utils'
import type { Template } from '../template'
import { PROGRAM_STATUS } from '../template'

const BRAND_NAME = 'Merchant M'

export const MerchantM: Template = {
  usePrequal: false,
  gradient: 'linear-gradient(to bottom, #2A206A 19%, #2A206A);',
  cardOrientation: 'horizontal',
  programStatus: PROGRAM_STATUS.Active,
  hiddenPaths: [],
  copy: {
    subdomain: 'merchant-m',
    brandName: BRAND_NAME,
    cardName: 'Visa® Business Card',
    bankPartner: 'Emprise Bank',
    email: 'merchant-m@example.com',
    links: {
      homepage: 'https://www.marqeta.com/',
      legal: {
        loyaltyProgram:
          'https://static.usepower.com/702C028F-C237-45E9-9318-4E98BEFEF263/loyalty_program_rules.pdf',
        autopayAgreement:
          'https://static.usepower.com/702C028F-C237-45E9-9318-4E98BEFEF263/autopay_terms.pdf',
        cardholderAgreement:
          'https://static.usepower.com/702C028F-C237-45E9-9318-4E98BEFEF263/cardholder_agreement.pdf',
        ecommDisclosure:
          'https://static.usepower.com/702C028F-C237-45E9-9318-4E98BEFEF263/electronic_communications_disclosure.pdf',
        autodialerAuth:
          'https://static.usepower.com/702C028F-C237-45E9-9318-4E98BEFEF263/autodialer_authorization.pdf',
        creditReportAuth:
          'https://static.usepower.com/702C028F-C237-45E9-9318-4E98BEFEF263/credit_report_authorization.pdf',
        welcomeBonus: '',
      },
    },
    images: {
      logo: {
        src: '/brand/images/merchant-m/logo.svg',
        width: 120,
        height: 50,
      },
      cardFrontSrc: '/brand/images/merchant-m/card-front.svg',
    },

    application: {
      title: ['Get free trips with a premium credit card'],
      incentives: createDefaultIncentivesCopy(BRAND_NAME),
      marginLow: 9.99,
      marginHigh: 22.99,
      feesRows: createDefaultFeeRows(),
    },
    rewards: createDefaultRewards(),
    offers: createDefaultOffers(BRAND_NAME),
    phoneNumber: '+1.831.228.3307',
    benefits: [],
  },
  theme: {
    palette: {
      primary: {
        main: '#202020',
      },
      secondary: {
        main: '#FFFFFF',
      },
      accent: {
        main: '#2A206A',
      },
      neutral: {
        main: '#B5B6BA',
        light: '#DADADC',
        dark: '#7D7F86',
      },
      error: {
        main: '#D60B09',
        light: '#FDEDED',
        dark: '#9C0000',
      },
      warning: {
        main: '#ED8916',
        light: '#FFB94D',
        dark: '#B55B00',
      },
      info: {
        main: '#2A206A',
        light: '#6881FF',
        dark: '#002DB9',
      },
      success: {
        main: '#00b961',
        light: '#5aec90',
        dark: '#008835',
      },
      brand: {
        main: '#3C33B2',
        light: '#3C33B2',
      },
      background: {
        default: '#FFFFFF',
        light: '#F2F2F7',
      },
    },
    typography: {
      fontFamily: 'SofiaPro',
      h1: createTypographyStyle(700, 40, '50px'),
      h2: createTypographyStyle(700, 32, '40px'),
      h3: createTypographyStyle(700, 24, '32px'),
      subtitle1: createTypographyStyle(700, 16, '24px'),
      subtitle2: createTypographyStyle(700, 14, '26px'),
      body1: createTypographyStyle(400, 16, '24px'),
      body2: createTypographyStyle(400, 14, '26px'),
      overline: createTypographyStyle(700, 12, '16px'),
      caption: createTypographyStyle(400, 12, '20px'),
      button: {
        textTransform: 'none',
      },
    },
    shape: {
      borderRadius: 1,
    },
    components: {
      MuiCssBaseline: {
        styleOverrides: SOFIA_PRO_FONT_FACE,
      },
      MuiAppBar: {
        styleOverrides: {
          root: {
            backgroundColor: '#2A206A',
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            padding: '12px 24px',
          },
          contained: {
            backgroundColor: '#3C33B2',
            ':hover': {
              backgroundColor: '#362ea0',
            },
          },
        },
      },
    },
  },
}
