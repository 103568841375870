import {
  SOFIA_PRO_FONT_FACE,
  createBrandLegalLinks,
  createDefaultApplicationCopy,
  createDefaultOffers,
  createDefaultRewards,
  createTypographyStyle,
} from '~/constants/templates/utils'
import type { Template } from '../template'
import { PROGRAM_STATUS } from '../template'

const BRAND_NAME = 'Comcred'

export const Comcred: Template = {
  usePrequal: false,
  gradient: '#202020',
  cardOrientation: 'horizontal',
  programStatus: PROGRAM_STATUS.Active,
  hiddenPaths: [],
  copy: {
    subdomain: 'comcred',
    brandName: BRAND_NAME,
    cardName: 'Visa® Business Card',
    bankPartner: 'Emprise Bank',
    email: 'comcred@example.com',
    links: {
      homepage: 'https://www.marqeta.com/',
      legal: createBrandLegalLinks('b0187038-5fe0-44e5-a2e3-87c71f1f25fe'),
    },
    images: {
      logo: {
        src: '/brand/images/comcred/logo.svg',
        width: 120,
        height: 40,
      },
      cardFrontSrc: '/brand/images/comcred/card.svg',
    },

    application: createDefaultApplicationCopy(BRAND_NAME),
    rewards: createDefaultRewards(),
    offers: createDefaultOffers(BRAND_NAME),
    phoneNumber: '+1.831.228.3307',
    benefits: [],
  },
  theme: {
    palette: {
      primary: {
        main: '#202020',
      },
      secondary: {
        main: '#FFFFFF',
      },
      accent: {
        main: '#DC5E4D',
      },
      neutral: {
        main: '#B5B6BA',
        light: '#DADADC',
        dark: '#7D7F86',
      },
      error: {
        main: '#D60B09',
        light: '#FDEDED',
        dark: '#9C0000',
      },
      warning: {
        main: '#ED8916',
        light: '#FFB94D',
        dark: '#B55B00',
      },
      info: {
        main: '#DC5E4D',
        light: '#6881FF',
        dark: '#002DB9',
      },
      success: {
        main: '#00b961',
        light: '#5aec90',
        dark: '#008835',
      },
      brand: {
        main: '#DC5E4D',
        light: '#df6e5f',
      },
      background: {
        default: '#FFFFFF',
        light: '#F2F2F7',
      },
    },
    typography: {
      fontFamily: 'SofiaPro',
      h1: createTypographyStyle(700, 40, '50px'),
      h2: createTypographyStyle(700, 32, '40px'),
      h3: createTypographyStyle(700, 24, '32px'),
      subtitle1: createTypographyStyle(700, 16, '24px'),
      subtitle2: createTypographyStyle(700, 14, '26px'),
      body1: createTypographyStyle(400, 16, '24px'),
      body2: createTypographyStyle(400, 14, '26px'),
      overline: createTypographyStyle(700, 12, '16px'),
      caption: createTypographyStyle(400, 12, '20px'),
      button: {
        textTransform: 'none',
      },
    },
    shape: {
      borderRadius: 1,
    },
    components: {
      MuiCssBaseline: {
        styleOverrides: SOFIA_PRO_FONT_FACE,
      },
      MuiButton: {
        styleOverrides: {
          root: {
            borderRadius: 4,
          },
        },
      },
      MuiAppBar: {
        styleOverrides: {
          root: {
            backgroundColor: '#DC5E4D',
          },
        },
      },
      MuiLink: {
        styleOverrides: {
          root: {
            variants: [
              {
                props: { variant: 'hyperlink' },
                style: { color: 'blue' },
              },
            ],
          },
        },
      },
    },
  },
}
