import type { JWTPayload } from 'jose'
import { createRemoteJWKSet, jwtVerify } from 'jose'

/**
 * Verify the authenticity of a given JWT token. If the token is successfully verified then its contents are returned.
 *
 * @param {string} issuerUrl The issuer that originated the token
 * @param {string} audience The audience for which the token should be verified
 * @param {string} jwt The token to be verified
 * @return {*}  {Promise<JWTPayload>} The payload of the verified token
 */
export const verify = async (
  issuerUrl: string,
  audience: string,
  jwt: string
): Promise<JWTPayload> => {
  const jwks = createRemoteJWKSet(new URL(`${issuerUrl}.well-known/jwks.json`))

  const { payload } = await jwtVerify(jwt, jwks, {
    issuer: issuerUrl,
    audience: audience,
    algorithms: ['RS256'],
  })

  return payload
}

/**
 * Check whether or not the given token payload has any of the given set of scopes.
 *
 * @param {string[]} scopes Scopes expected of the token
 * @param {JWTPayload} payload The token's payload
 * @return {*}  {boolean} Whether the token has the expected payload
 */
export const hasAnyScope = (scopes: string[], payload: JWTPayload): boolean => {
  const jwtScopes: string[] =
    (typeof payload.scope === 'string' && payload.scope.split(' ')) || []

  return scopes.some(scope => jwtScopes.includes(scope))
}
